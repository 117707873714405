<template>
  <div class="Row" @click="onClick">
    <slot>
    <div class="label">
      <i v-if="icon" :class="'iconfont ' + icon"></i>
      {{label}}
    </div>
    <div class="text">
      <slot name="text">
        {{text}}
      </slot>
    </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: "Row",
  props: {
    icon: {
      type: String,
      default: () => {
        return "";
      }
    },
    label: {
      type: String,
      default: ()=>{
        return '';
      }
    },
    text: {
      type: String,
      default:()=>{
        return '';
      }
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click',e);
    }
  }
}

</script>

<style scoped lang="scss">
.Row {
  display: flex;
  border-bottom: $border;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;

  .label {
    min-width: 30%;
    color: #888;
    text-align: left;
  }
  .text {
    min-width: 30%;
    flex: 1 1 auto;
    text-align: right;
    font-size: 1.2em;
  }
}

.Row:first-child{
  border-top: $border;
}
</style>