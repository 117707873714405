<template>
  <div>
    <div class="opt-bar flex">
      <el-input size="small"
          @keyup.enter.native="query"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="query"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="query" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
    </div>
    <split-pane split="vertical" class="pane" :default-percent='70' :resizerThickness="5">
      <template slot="paneL">    
        <el-table :data="list" element-loading-text="Loading..."
          size="small"
          border fit highlight-current-row
          header-row-class-name="tableHeader"
          :height="tableHeight"
          :row-class-name="tableRowClass"
          @row-click="handleRowClick"
          row-key="id"
          default-expand-all
        >
          <el-table-column label="模板ID" width="350" align="left">
            <template slot-scope="scope">
              {{ scope.row.template_id }}
            </template>
          </el-table-column>
          <el-table-column label="标题"  align="left">
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column label="行业" width="80" align="left">
            <template slot-scope="scope">
              {{ scope.row.primary_industry }}
            </template>
          </el-table-column>
          <el-table-column label="子行业" width="180" align="left">
            <template slot-scope="scope">
              {{ scope.row.deputy_industry }}
            </template>
          </el-table-column>      
        </el-table>
      </template>
      <template slot="paneR">
        <div class="right-panel">
          <!-- <div class="right-opt">
            
          </div> -->
          <el-card class="box-card">        
            <div slot="header" class="clearfix">
              <span>{{current.title}} 示例</span>
              <el-button style="float: right; padding: 3px 0" type="text">操作</el-button>
            </div>    
            <div class="example" v-html="transExample(current.example)"></div>
          </el-card>

          <el-card class="box-card">        
            <div slot="header" class="clearfix">
              <span>模板内容</span>
              <el-button style="float: right; padding: 3px 0" type="text">操作</el-button>
            </div>    
            <div class="example" v-html="transExample(current.content)"></div>
          </el-card>
        </div>
      </template>
    </split-pane>    
  </div>
</template>


<script>
import splitPane from 'vue-splitpane'
import IconBtn from '../../components/btns/IconBtn';
import Row from '../../components/row/Row'
export default {
  components: { splitPane, IconBtn, Row},
  data(){
    return {
      tableHeight: 560,      
      list: [],
      current: false,
      skey: '',
    } //--return
  },//--data
  created(){
    this.resize();
    window.onresize = this.resize();
    this.query();
  },
  methods: {
    handleRowClick(e) {
      this.current = e;
      console.log(e)
    },
    query() {     
      if (!this.global.plat) {
        this.toast('请先选择应用~')
        return;
      }
      var pm = {
        appid: this.global.plat.appid
      }
      this.post('api/mp/tpl_list', pm, res=>{
        if (res.code == 0) {
          this.list = res.data;
        } else {
          this.$notify.error({ 
            title: '出错了',
            message: res.msg
          })
        }
      })
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 130
    },
    transExample(txt) {
      if (!txt) return "";
      return txt.replaceAll('\n', '<br/>');
    },
  }//-methods
};
</script>

<style scoped lang="scss">
.opt-bar {
  height: 32px;
}
.right-opt {
  height: 30px;
  border-bottom: 1px solid #f8f8f8;
}
.box-card {
  text-align: left;
}
.example {
  line-height: 2em;
}
</style>